<template>
  <div>
    <el-card class="box-card">
      <div class="search-contain">
        <div class="item-contain">
          <!--操作类型-->
          <div class="vm search-item search-item2 mr40">
            <div class="vm search-text">操作类型</div>
            <div class="vm select">
              <el-select v-model="search.action_type">
                <el-option label="全部" :value="0"></el-option>
                <el-option
                  v-for="item in options1"
                  :key="item.label"
                  :label="item.label"
                  :value="item.val"
                ></el-option>
              </el-select>
            </div>
          </div>
          <!--操作模块-->
          <div class="vm search-item search-item2">
            <div class="vm search-text">操作模块</div>
            <div class="vm select">
              <el-select v-model="search.module_name">
                <el-option label="全部" :value="0"></el-option>
                <el-option
                  v-for="item in options2"
                  :key="item.label"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="item-contain">
          <!--操作账号-->
          <div class="vm search-item search-item8 mr40">
            <div class="vm search-text">操作账号</div>
            <div class="vm select">
              <div class="flex-center">
                <el-input
                  v-model="search.umobile"
                  clearable
                  placeholder="输入手机号"
                ></el-input>
              </div>
            </div>
          </div>
          <!--模块操作-->
          <div class="vm search-item search-item8">
            <div class="vm search-text">操作人</div>
            <div class="vm select">
              <div class="flex-center">
                <el-input
                  v-model="search.name"
                  clearable
                  placeholder="输入老师名称"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-center">
          <el-button @click="filterStart" type="primary" class="filter-button">
            筛选
          </el-button>
          <div @click="init" class="filter-text">清除所有筛选项</div>
        </div>
      </div>
      <pagination2 :option="originSearch" url="/log/logList">
        <template v-slot:default="{ tableData }">
          <el-table
            :data="tableData"
            style="width: 100%"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
          >
            <el-table-column prop="uname" label="操作账号">
              <template slot-scope="scope">
                <div class="userInfo">
                  <img class="left" :src="scope.row.uphoto" />
                  <div class="right">
                    <div class="t1">{{ scope.row.uname }}</div>
                    <div class="t2">
                      {{ scope.row.umobile }}
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="is_jigouadmin " label="操作时间">
              <template slot-scope="scope">
                {{ scope.row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss') }}
              </template>
            </el-table-column>
            <el-table-column
              prop="module_name"
              label="操作模块"
            ></el-table-column>
            <el-table-column
              prop="menu_name"
              label="操作菜单"
            ></el-table-column>
            <el-table-column prop="action_type" label="操作类型">
              <template slot-scope="scope">
                {{ scope.row.action_type | actionType }}
              </template>
            </el-table-column>
            <el-table-column prop="user_ip" label="操作人IP"></el-table-column>
            <el-table-column prop="content" label="操作内容"></el-table-column>
          </el-table>
        </template>
      </pagination2>
    </el-card>
  </div>
</template>
<script>
export default {
  name: 'log',

  data() {
    return {
      originSearch: {
        //下拉框以及输入框
        action_type: 0,
        module_name: 0,
        umobile: '',
        name: '',
      },

      search: {
        //下拉框以及输入框
        action_type: 0,
        module_name: 0,
        umobile: '',
        name: '',
      },

      options1: [
        // { label: '新增', val: 1 },
        { label: '修改', val: 2 },
        { label: '删除', val: 3 },
        { label: '下载/导出', val: 4 },
      ],
      options2: [
        { label: '课程管理' },
        { label: '版权保护' },
        { label: '教务管理' },
        { label: '素材库' },
        { label: '助学工具' },
        { label: '资产管理' },
        { label: '营销中心' },
        { label: '设置中心' },
      ],
    }
  },

  filters: {
    actionType(type) {
      let text
      switch (Number(type)) {
        case 1:
          text = '新增'
          break
        case 2:
          text = '修改'
          break
        case 3:
          text = '删除'
          break
        case 4:
          text = '下载/导出'
          break
        case 5:
          text = '登录'
          break
      }
      return text
    },
  },

  methods: {
    filterStart() {
      this.originSearch = _.assign({}, this.search)
    },

    init() {
      this.search = {
        //下拉框以及输入框
        action_type: 0,
        module_name: 0,
        umobile: '',
        user: '',
      }
      this.originSearch = _.assign({}, this.search)
    },
  },
}
</script>
<style lang="less" scoped>
.search-contain {
  font-size: 0;
  position: relative;
  padding: 30px 20px;
  ::v-deep .el-input,
  ::v-deep .select {
    height: 36px;
    .el-input__inner {
      height: 36px;
    }
    .el-input__icon {
      line-height: 36px;
    }
    .el-date-editor .el-range__icon {
      line-height: 31px;
    }
    .el-range-separator {
      line-height: 29px;
    }
  }
  .search-item {
    margin-bottom: 20px;
  }
  .search-text {
    min-width: 56px;
    font-size: 14px;
    line-height: 19px;
    margin-right: 10px;
    color: rgba(51, 51, 51, 1);
  }
  .filter-text {
    font-size: 14px;
    line-height: 19px;
    margin-left: 20px;
    cursor: pointer;
    color: rgba(10, 162, 155, 1);
  }
  ::v-deep .filter-button,
  ::v-deep .exportTable .el-button {
    width: 100px;
    height: 36px;
    line-height: 10px;
  }
  ::v-deep .placeholder333 {
    input::-webkit-input-placeholder {
      color: #333;
    }
  }
  ::v-deep .search-item1,
  ::v-deep .search-item8,
  ::v-deep .search-item2,
  ::v-deep .search-item6 {
    .select {
      width: 200px;
    }
  }
}
.userInfo {
  display: flex;
  align-items: center;
  .left {
    width: 34px;
    height: 34px;
    border-radius: 17px;
  }
  .right {
    margin-left: 10px;
    .t1 {
      font-size: 14px;
      line-height: 19px;
      color: rgba(51, 51, 51, 1);
    }
    .t2 {
      font-size: 12px;
      line-height: 16px;
      margin-top: 4px;
      color: rgba(153, 153, 153, 1);
    }
  }
}
</style>
